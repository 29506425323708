import React, { useEffect, useState } from 'react';
import TweetCard from './TweetCard';
import Loading from '../Components/Loading';
import usePrevious from '../hooks/usePrevious';
import InfiniteScroll from 'react-infinite-scroller';
const take = 100;

const TweetsList = ({searchTerm}) => {
    const [tweets, setTweets] = useState([]);
    const prevousTweets = usePrevious(tweets);
    const [isLoading, setIsloading] = useState(false);
    const [skipCount, setSkipCount] = useState(0);
    const prevousSkipCount = usePrevious(skipCount);

    const fetchItems = () => {
        const url = `/api/tweets?skip=${skipCount * take}&take=${take}&searchTerm=${searchTerm}`;
        setIsloading(true);
        fetch(url, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const newTweets = skipCount > 0 && prevousTweets ? prevousTweets : [];
                setTweets(newTweets.concat(data));
            })
            .finally(() => setIsloading(false));
    }

    useEffect(fetchItems, [skipCount, searchTerm]);

    return (
        <div>
            <h1 class="h3 mb-2 text-gray-800">{searchTerm && searchTerm.length > 0 ? `Search Results for "${searchTerm}"` : "All Tweets"}</h1>
            {isLoading && <Loading />}
            <InfiniteScroll
                loadMore={() => setSkipCount((prevousSkipCount ?? 0) + 1)}
                hasMore={tweets && tweets.length === (skipCount + 1) * take}
            >
                <div className="row">
                    {tweets.map((tweet) =>
                        <div className="col-12 col-md-6 animated--grow-in">
                            <TweetCard key={tweet.id} {...tweet} searchTerm={searchTerm} />
                        </div>
                    )}
                </div>
            </InfiniteScroll>
        </div>
    )
}

export default TweetsList