import * as happynessLevels from '../constants/happynessLevels';

export const getAverageSentiment = (tweetObjects) => {
    if(tweetObjects === null || tweetObjects.length === 0)
        return null;
    const tweetObjectsProcessed = tweetObjects.map(to => to.Score).filter(score => score !== unprocessedSentiment);
    return tweetObjectsProcessed.length > 0 
        ? tweetObjectsProcessed.reduce((a, b) => a + b, 0) / tweetObjectsProcessed.length
        : null;
}

export const UNKNOWN = {
    icon: "fas fa-question",
    color: "info",
    value: "unknown"
};

export const ECSTATIC = {
    icon: "far fa-grin-beam",
    color: "success",
    value: "Ecstatic"
};

export const HAPPY = {
    icon: "far fa-grin",
    color: "primary",
    value: "Happy"
};

export const NEUTRAL = {
    icon: "far fa-meh",
    color: "secondary",
    value: "Neutral"
};

export const ANGRY = {
    icon: "far fa-frown",
    color: "warning",
    value: "Angry"
};

export const FURIOUS = {
    icon: "far fa-angry",
    color: "danger",
    value: "Furious"
};

export const LOWEST_TO_HIGHEST = [
    FURIOUS,
    ANGRY,
    NEUTRAL,
    HAPPY,
    ECSTATIC
];

export const getSentimentDetails = (tweetObjects) => {
    const sentimentAverage = getAverageSentiment(tweetObjects);

    if(sentimentAverage === null) {
        return {
            average: sentimentAverage,
            ...UNKNOWN
        };
    } else if(sentimentAverage > happynessLevels.ECSTATIC) {
        return {
            average: sentimentAverage,
            ...ECSTATIC
        };
    } else if(sentimentAverage > happynessLevels.HAPPY) {
        return {
            average: sentimentAverage,
            ...HAPPY
        };
    } else if(sentimentAverage > happynessLevels.NEUTRAL) {
        return {
            average: sentimentAverage,
            ...NEUTRAL
        };
    } else if(sentimentAverage > happynessLevels.ANGRY) {
        return {
            average: sentimentAverage,
            ...ANGRY
        };
    } else if(sentimentAverage > happynessLevels.FURIOUS) {
        return {
            average: sentimentAverage,
            ...FURIOUS
        };
    }
}


const unprocessedSentiment = .5 // not run