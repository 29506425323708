export const getPhraseDetails = (tweetObjects) => {
    const allPhrases = [].concat.apply([], tweetObjects.map((tweetObject) => tweetObject.Phrases));
    const filteredPhrases = allPhrases.filter(phrasesFilter);
    const uniquePhrases = Array.from(new Set(filteredPhrases));
    return uniquePhrases.map(uniquePhrase => ({
        phrase: uniquePhrase,
        count: filteredPhrases.filter(phrase => phrase===uniquePhrase).length
    }));
}

const phrasesToIgnore = ["RT", "realDonaldTrump", "amp"];

export const phrasesFilter = (phrase) => phrasesToIgnore.filter(toIgnore => toIgnore.toLowerCase() === phrase.toLowerCase()).length === 0;